<!-- =========================================================================================
  Author: Nada Azzam
========================================================================================== -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Personal info."
      class="user-list-filters mb-8 vs-con-loading__container"
      id="div-loading"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form ref="form" slot-scope="{ validate }" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Employee Name
              </label>
              <validation-provider
                name="full_name"
                rules="required|min:4"
                v-slot="{ errors }"
              >
                <vs-input
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  icon-no-border
                  name="full_name"
                  v-model="user.full_name"
                />
              </validation-provider>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>COMPANY
              </label>
              <validation-provider
                name="company_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name"
                  label="name"
                  :options="companies"
                  name="company_id"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.company_id"
                  class="mb-1"
                  placeholder="Company"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>First Name
                  </label>
                  <validation-provider
                    name="first_name"
                    rules="required|min:1"
                    v-slot="{ errors }"
                  >
                    <vs-input
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-chevron-right"
                      name="first_name"
                      icon-no-border
                      v-model="user.first_name"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Last Name
                  </label>
                  <validation-provider
                    name="last_name"
                    rules="required|min:1"
                    v-slot="{ errors }"
                  >
                    <vs-input
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-chevron-right"
                      icon-no-border
                      name="last_name"
                      v-model="user.last_name"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Employee code
              </label>
              <validation-provider
                name="code"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <vs-input
                  name="code"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  icon-no-border
                  v-model="user.code"
                />
              </validation-provider>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>National ID
              </label>
              <validation-provider
                name="nationalId"
                rules="required|min:14||max:14|numeric"
                v-slot="{ errors }"
              >
                <vs-input
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  class="w-full"
                  name="nationalId"
                  icon-pack="feather"
                  icon="icon-chevron-right"
                  icon-no-border
                  v-model="user.nationalId"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>DEPARTMENT
              </label>
              <validation-provider
                name="department_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  label="name"
                  :options="departments"
                  name="department_id"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.department_id"
                  class="mb-4 md:mb-0"
                  placeholder="Department"
                  :required="!!errors[0]"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
              <!-- {{department}} -->
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Private mob.</label>
              <validation-provider
                name="private_mobile"
                :rules="{
                  required: true,
                  regex: '^(\\+20|0020|020|20|0)?1(0|1|2|5)[0-9]{8}$',
                  numeric: true
                }"
                v-slot="{ errors }"
              >
                <vs-input
                  name="private_mobile"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-phone"
                  icon-no-border
                  v-model="user.private_mobile"
                />
              </validation-provider>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Job Title
              </label>
              <validation-provider
                name="jobtitle_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  name="jobtitle_id"
                  label="name"
                  :options="jobTitles"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.jobtitle_id"
                  class="mb-1"
                  :required="!!errors[0]"
                  placeholder="Job Title"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Business mob.
              </label>
              <validation-provider
                name="buisness_mobile"
                :rules="{
                  required: true,
                  regex: '^(\\+20|0020|020|20|0)?1(0|1|2|5)[0-9]{8}$',
                  numeric: true
                }"
                v-slot="{ errors }"
              >
                <vs-input
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-phone"
                  name="buisness_mobile"
                  icon-no-border
                  v-model="user.buisness_mobile"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>CATEGORY
              </label>
              <validation-provider
                name="category_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  label="name"
                  :options="categories"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.category_id"
                  class="mb-4 md:mb-0"
                  placeholder="Category"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>E-mail
              </label>
              <validation-provider
                name="email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <vs-input
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-mail"
                  icon-no-border
                  name="email"
                  v-model="user.email"
                />
              </validation-provider>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Serial mob.</label>
              <validation-provider
                name="serial_mobile"
                rules="min:1"
                v-slot="{ errors }"
              >
                <vs-input
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  icon-no-border
                  name="serial_mobile"
                  v-model="user.serial_mobile"
                  disabled="true"
                />
              </validation-provider>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75 mr-3">
                <span class="text-primary">*</span>Start date
              </label>
              <validation-provider
                name="start_date"
                rules="required"
                v-slot="{ errors }"
              >
                <flat-pickr
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  name="start_date"
                  :config="configFromdateTimePicker"
                  v-model="user.start_date"
                  icon-pack="feather"
                  icon="icon-calendar"
                  icon-no-border
                  placeholder="Start Date"
                  @on-change="onFromChange"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Roles
              </label>
              <validation-provider
                name="role"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  label="name"
                  :options="roles"
                  :clearable="false"
                  name="role"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.roles"
                  class="mb-4 md:mb-0"
                  multiple
                  placeholder="Role"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
            <!-- <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Direct HRs
              </label>
              <validation-provider
                name="directHr"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  label="FullName"
                  name="directHr"
                  :options="directHrs"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.directHr"
                  class="mb-4 md:mb-0"
                  placeholder="Direct HRs"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to"
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div> -->
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <label class="text-md opacity-75">Profile photo :</label>
                  <br />
                  <a v-bind:href="prevPhoto">show photo</a>
                  <validation-provider name="file" v-slot="{ errors }">
                    <input
                      name="file"
                      type="file"
                      id="file"
                      ref="file"
                      @change="onFileChange"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                    <span>
                      <div
                        v-show="!!errors[0]"
                        class="
                          con-text-validation
                          span-text-validation-danger
                          vs-input--text-validation-span
                          v-enter-to
                        "
                      >
                        <span class="span-text-validation">
                          {{ errors[0] }}
                        </span>
                      </div>
                    </span>
                  </validation-provider>
                </div>
                <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
                  <img
                    v-if="image"
                    style="width: 100%; height: 100%"
                    :src="image"
                  />
                </div>
              </div>
            </div>

            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2"></div>
          </div>

          <br />
          <hr />
          <br />
          <b>Authorization areas.</b>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>City
              </label>
              <validation-provider
                name="city_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  name="city_id"
                  label="name"
                  autocomplete="off"
                  :options="cities"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.city_id"
                  class="mb-1"
                  multiple
                  :required="!!errors[0]"
                  placeholder="City"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Areas
              </label>
              <validation-provider
                name="area_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  label="name"
                  name="area_id"
                  :options="areas"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="user.areas"
                  class="mb-1"
                  multiple
                  :required="!!errors[0]"
                  placeholder="Area"
                />
                <span>
                  <div
                    v-show="!!errors[0]"
                    class="
                      con-text-validation
                      span-text-validation-danger
                      vs-input--text-validation-span
                      v-enter-to
                    "
                  >
                    <span class="span-text-validation">
                      {{ errors[0] }}
                    </span>
                  </div>
                </span>
              </validation-provider>
            </div>
          </div>
          <!-- {{ area }} -->

          <!-- <br />
        <hr />
        <br />

        <b>Vacations.</b>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>user_planned_vacation_days
            </label>
                     <validation-provider name="areas" rules="required|min:8" 
                                     v-slot="{ errors }">
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              type="number"
              v-model="user_planned_vacation_days"
            />
                     </validation-provider>
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>user_remaining_planned_days
            </label>
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              type="number"
              v-model="user_remaining_planned_days"
              disabled
            />
          </div>

          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>user_casual_vacation_days
            </label>
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              type="number"
              v-model="user_casual_vacation_days"
            />
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>user_remaining_casual_days
            </label>
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              type="number"
              v-model="user_remaining_casual_days"
              disabled
            />
          </div>
        </div>

        <br />
        <hr />
        <br />

        <b>Mobile.</b>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>fcm_device_id
            </label>
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              v-model="fcm_device_id"
            />
          </div>
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75">
              <span class="text-primary">*</span>fcm_device_type
            </label>
            <v-select :reduce="name => name.id"
              :options="fcm_device_types"
              :clearable="false"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="fcm_device_type"
              class="mb-4"
              placeholder="FCM Device Type"
            />
          </div>
        </div>-->
          <br />
          <hr />
          <br />
          <div class="vx-row">
            <div class="vx-col w-full mb-3">
              <b>Direct Manger</b>
              <br />
              <div
                v-for="(manger, index) of user.directmanagers"
                :key="index"
                class="vx-row"
              >
                <div class="vx-col md:w-1/3 sm:w-1/3 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Direct manager
                  </label>
                  <validation-provider
                    :vid="'vp' + manger.id"
                    name="directmanager"
                    rules="required|min:1"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :reduce="name => name.id"
                      label="FullName"
                      name="directmanager"
                      :options="directManagers"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="manger.manager_id"
                      class="mb-4 md:mb-0"
                      placeholder="Direct Manager"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                    <span>
                      <div
                        v-show="!!errors[0]"
                        class="
                          con-text-validation
                          span-text-validation-danger
                          vs-input--text-validation-span
                          v-enter-to
                        "
                      >
                        <span class="span-text-validation">
                          {{ errors[0] }}
                        </span>
                      </div>
                    </span>
                  </validation-provider>
                </div>
                <div class="vx-col md:w-1/3 sm:w-1/3 w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>level
                  </label>
                  <validation-provider
                    :vid="'vp' + index"
                    :name="'level' + index"
                    rules="required|max:2|numeric"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :reduce="name => name.id"
                      label="name"
                      :name="'level' + index"
                      :options="levels"
                      :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="manger.level"
                      class="mb-4 md:mb-0"
                      placeholder="Level"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                    <!-- <vs-input
                    class="w-full"
                    icon-pack="feather"
                    icon="icon-user"
                    icon-no-border
                    :name="'level' + index"
                    type="text"
                    v-model="manger.level"
                    :danger="!!errors[0]"
                    :danger-text="errors[0]"
                  /> -->
                    <span>
                      <div
                        v-show="!!errors[0]"
                        class="
                          con-text-validation
                          span-text-validation-danger
                          vs-input--text-validation-span
                          v-enter-to
                        "
                      >
                        <span class="span-text-validation">
                          {{ errors[0] }}
                        </span>
                      </div>
                    </span>
                  </validation-provider>
                </div>
                <div
                  class="vx-col md:w-1/3 sm:w-1/3 w-full m-auto"
                  style="
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                  "
                >
                  <vs-button
                    style="floate: inline-start !important"
                    @click="addManger()"
                    radius
                    color="success"
                    class="mr-2"
                    type="border"
                    icon="add"
                  ></vs-button>
                  <vs-button
                    style="floate: inline-start !important"
                    v-if="index != 0"
                    @click="removeManger(index)"
                    radius
                    color="danger"
                    type="border"
                    icon="delete"
                  ></vs-button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div class="vx-row">
            <div class="vx-col w-3/4">
              <b>Time .</b>
            </div>
            <div class="vx-col w-1/4">
              <vs-button
                class="float-right"
                color="dark"
                type="line"
                icon="refresh"
                @click="resetTime"
              ></vs-button>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>From
              </label>
              <validation-provider
                name="from"
                rules="min:4"
                v-slot="{ errors }"
              >
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-chevron-right"
                  icon-no-border
                  type="time"
                  v-model="user.time.from"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>To
              </label>
              <validation-provider name="to" rules="min:4" v-slot="{ errors }">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-chevron-right"
                  icon-no-border
                  type="time"
                  v-model="user.time.to"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2 mt-2">
              <vx-card class="mt-0" no-shadow card-border>
                <label class="text-sm opacity-75"> DAYS </label>
                <vs-row>
                  <vs-col
                    v-for="(day, index) in allDays"
                    :key="day + index"
                    type="flex"
                    vs-justify="left"
                    vs-align="left"
                    vs-w="6"
                  >
                    <vs-checkbox
                      class="py-2"
                      :vs-value="day.name"
                      v-model="user.days"
                      >{{ day.name }}</vs-checkbox
                    >
                  </vs-col>
                </vs-row>
              </vx-card>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <div class="vx-row">
            <div class="vx-col md:w-1/3 sm:w-1/3 w-full mb-3">
              <b>Notes</b>
              <br />
              <validation-provider
                name="note"
                rules="required|min:4"
                v-slot="{ errors }"
              >
                <vs-textarea
                  placeholder="write any notes here...!"
                  style="
                    outline: none;
                    resize: none;
                    border-radius: 7px;
                    padding: 5px;
                  "
                  v-model="user.note"
                  name="note"
                  rows="10"
                  cols="60"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                ></vs-textarea>
              </validation-provider>
            </div>
          </div>
          <br />
          <hr />
          <br />
          <b>Account security.</b>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Password
              </label>
              <validation-provider
                name="password"
                rules="required|min:8 "
                v-slot="{ errors }"
              >
                <vs-input
                  class="w-full"
                  type="password"
                  icon-pack="feather"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  icon="icon-lock"
                  icon-no-border
                  name="password"
                  v-model="user.password"
                  autocomplete="off"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2"></div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Confirm password</label>
              <validation-provider
                name="c_password"
                rules="required|c_password:@password"
                v-slot="{ errors }"
              >
                <vs-input
                  class="w-full"
                  type="password"
                  icon-pack="feather"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  icon="icon-lock"
                  name="c_password"
                  icon-no-border
                  v-model="user.c_password"
                  autocomplete="off"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2"></div>
          </div>

          <br />
          <hr />
          <br />
          <div class="vx-row">
            <div class="vx-col md:w-1/3 sm:w-1/3 w-full mb-3">
              <validation-provider
                name="areas"
                rules="required"
                v-slot="{ errors }"
              >
                <vs-checkbox
                  class="py-2"
                  v-model="user.is_topmanager"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                  >Is Top Manager</vs-checkbox
                >
              </validation-provider>
            </div>
          </div>

          <vs-button type="filled" class="mb-2" @click="validate().then(add)"
            >SUBMIT</vs-button
          >
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import {
  required,
  min,
  email,
  numeric,
  regex,
  max
} from "vee-validate/dist/rules";
import vSelect from "vue-select";

import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import moduleDepartmentManagement from "@/store/get-all-departments/moduleDepartmentManagement.js";

import moduleCategoriesManagement from "@/store/get-all-categories/moduleCategoriesManagement.js";
import moduleEmployeeManagement from "@/store/get-all-employees/moduleEmployeeManagement.js";
import moduleRolesManagement from "@/store/get-all-roles/moduleRolesManagement.js";
import moduleJobTitleManagement from "@/store/get-all-jobs/moduleJobTitleManagement.js";
import moduleCitiesManagement from "@/store/get-all-cities/moduleCitiesManagement.js";
import moduleAreasManagement from "@/store/get-all-areas/moduleAreasManagement.js";

import flatPickr from "vue-flatpickr-component";
// import Cookie from "js-cookie";
import "flatpickr/dist/flatpickr.css";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import endpoints from "../../../endpoints";
// import endpoints from "../../../endpoints";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty"
});
extend("email", {
  ...email,
  message: "Email must be valid"
});
extend("min", {
  ...min,
  message: "field may not be less than {length} characters"
});
extend("max", {
  ...max,
  message: "field may not be less than {length} characters"
});
extend("numeric", {
  ...numeric,
  message: "field should be a number"
});
extend("regex", {
  ...regex,
  message: "{_field_} should start with +010 or 020"
});

extend("c_password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});
// Store Module

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr
  },
  watch: {
    "user.company_id"(obj) {
      // console.log(obj);
      this.user.department_id = null;
      this.user.jobtitle_id = null;
      this.departments = [];
      this.jobTitles = [];
      this.user.code = obj.code;
      if (obj.id) {
        this.$vs.loading({
          container: "#div-loading",
          scale: 0.6
        });
        axios
          .get(`${endpoints.API_URL}company/${obj.id}/departments`)
          .then(response => {
            this.$vs.loading.close("#div-loading > .con-vs-loading");
            this.departments = response.data.response.data;
          });
      }
    },
    "user.department_id"(obj) {
      this.user.jobtitle_id = null;
      this.jobTitles = [];
      if (obj && this.user.company_id) {
        this.$vs.loading({
          container: "#div-loading",
          scale: 0.6
        });
        axios
          .get(
            `${endpoints.API_URL}company_department_jobTitles?department_id=${obj}&company_id=${this.user.company_id.id}`
          )
          .then(response => {
            this.$vs.loading.close("#div-loading > .con-vs-loading");
            this.jobTitles = response.data.response.data;
          });
      }
    },
    "user.city_id"(obj) {
      this.areas = [];

      if (obj && obj.length) {
        this.$vs.loading({
          container: "#div-loading",
          scale: 0.6
        });
        obj.forEach(element => {
          axios
            .get(`${endpoints.CITY_ENDPOINT}/${element}/areas`)
            .then(response => {
              this.$vs.loading.close("#div-loading > .con-vs-loading");
              let itemsAreas = response.data.response.data;
              if (itemsAreas.length) {
                itemsAreas.forEach(item => {
                  let tempAreas = this.areas.map(i => i.id);
                  if (!tempAreas.includes(item.id)) {
                    this.areas.push(item);
                  }
                });
              }
              // check select areas in all areas
              this.user.areas.forEach(item => {
                let currentAreas = this.areas.map(i => i.id);
                if (!currentAreas.includes(item)) {
                  const itemIndex = this.user.areas.findIndex(u => u == item);
                  this.user.areas.splice(itemIndex, 1);
                }
              });
            });
        });
      } else {
        // clear select areas
        this.user.areas = [];
      }
    },
    "user.full_name"(obj) {
      if (obj.length > 1) {
        var fullName = obj.split(" ")[0];
        this.user.first_name = fullName;
      }
    },
    "user.time.from"(obj) {
      if (obj.length === 5) {
        this.user.time.from = obj + ":00";
      }
    },
    "user.time.to"(obj) {
      if (obj.length === 5) {
        this.user.time.to = obj + ":00";
      }
    }
  },
  data() {
    return {
      departments: [],
      jobTitles: [],
      allDays: [
        { id: 1, name: "Sunday" },
        { id: 2, name: "Monday" },
        { id: 3, name: "Tuesday" },
        { id: 4, name: "Wednesday" },
        { id: 5, name: "Thursday" },
        { id: 6, name: "Friday" },
        { id: 7, name: "Saturday" }
      ],
      levels: [
        { id: 1, name: "Level 1" },
        { id: 2, name: "Level 2" },
        { id: 3, name: "Level 3" },
        { id: 4, name: "Level 4" },
        { id: 5, name: "Level 5" },
        { id: 6, name: "Level 6" },
        { id: 7, name: "Level 7" },
        { id: 8, name: "Level 8" },
        { id: 9, name: "Level 9" },
        { id: 10, name: "Level 10" }
      ],
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date()
      },
      user: {
        full_name: null,
        first_name: null,
        last_name: null,
        nationalId: null,
        email: null,
        private_mobile: null,
        buisness_mobile: null,
        serial_mobile: null,
        code: null,
        company_id: null,
        category_id: null,
        department_id: null,
        jobtitle_id: null,
        start_date: null,
        city_id: null,
        note: null,
        areas: [],
        password: null,
        c_password: null,
        image: null,
        directmanagers: [{ manager_id: "", level: "" }],
        is_topmanager: false,
        roles: [],
        time: { from: null, to: null },
        days: []
      },
      areas: [],
      popupActive: false,
      configFromdateTimePicker: {
        allowInput: true,
        minDate: null,
        maxDate: new Date()
      },

      image: null,
      file: "",
      prevPhoto: null

      // Cell Renderer Components
    };
  },
  computed: {
    companies() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.company.items;
    },
    categories() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.category.items;
    },
    /* departments() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.department.items;
    }, */
    directManagers() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.employees.items;
    },
    /* jobTitles() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.jobTitle.items;
    }, */
    roles() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.roles.items;
    },

    cities() {
      // ////////console.log("hahahahahah : " + this.$store.state.company.items);
      return this.$store.state.city.items;
    }
  },

  methods: {
    resetTime() {
      this.user.time.from = "";
      this.user.time.to = "";
      this.user.days = [];
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.name = null;
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },

    add() {
      /* this.$refs.observer.validate().then(result => {
        if (result) {*/
      // console.log(this.user);
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      const formData = new FormData();

      formData.append("full_name", this.user.full_name);
      formData.append("first_name", this.user.first_name);
      formData.append("last_name", this.user.last_name);
      formData.append("nationalId", this.user.nationalId);
      formData.append("private_mobile", this.user.private_mobile);
      formData.append("buisness_mobile", this.user.buisness_mobile);
      formData.append("email", this.user.email);
      // formData.append("serial_mobile", this.user.serial_mobile);
      formData.append("company_id", this.user.company_id.id);
      formData.append("code", this.user.code);
      formData.append("category_id", this.user.category_id);
      formData.append("department_id", this.user.department_id);
      formData.append("jobtitle_id", this.user.jobtitle_id);
      formData.append("start_date", this.user.start_date);
      if (this.user.note) {
        formData.append("note", this.user.note);
      }

      // formData.append("city_id", this.user.city_id);
      formData.append("password", this.user.password);
      formData.append("c_password", this.user.c_password);
      if (this.user.image) {
        formData.append("image", this.user.image);
      }
      this.user.directmanagers.forEach((element, i) => {
        formData.append(
          "directmanagers[" + i + "][manager_id]",
          element.manager_id
        );
        formData.append("directmanagers[" + i + "][level]", element.level);
      });
      this.user.roles.forEach((element, i) => {
        formData.append("roles[" + i + "]", element);
      });
      this.user.areas.forEach((element, i) => {
        formData.append("areas[" + i + "]", element);
      });

      // formData.append("directHr", this.user.directHr);
      formData.append(
        "is_topmanager",
        this.user.is_topmanager == false ? 0 : 1
      );
      formData.append("time[from]", this.user.time.from);
      formData.append("time[to]", this.user.time.to);
      // if (this.user.time.from) {
      //   formData.append("time[from]", this.user.time.from);
      // }
      // if (this.user.time.to) {
      //   formData.append("time[to]", this.user.time.to);
      // }
      if (this.user.days === null) {
        // console.log('done')
      } else {
        this.user.days.forEach((element, i) => {
          formData.append("time[days][" + i + "]", element);
        });
      }
      //time
      // formData.append("time[from]", this.user.time.from);
      // formData.append("time[to]", this.user.time.to);
      // this.user.days.forEach((element, i) => {
      //   formData.append("time[days][" + i + "]", element);
      // });
      axios
        .post(endpoints.USER_ENDPOINT, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          if (response.data.status === 200) {
            this.$vs.notify({
              title: "Success",
              text: "Employee is added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            this.$router.push("/GetAll/Employee");
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          // console.log(error.response.data.errors);
          this.$vs.loading.close("#div-loading > .con-vs-loading");
          this.$vs.notify({
            title: "Error",
            text: error.response.data.errors[0],
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      /*   } else {
          this.$vs.notify({
            title: "Error",
            text: "Please make sure to enter all data",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });*/
    },
    openPopUp() {
      this.popupActive = true;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.user.image = this.$refs.file.files[0];
      this.createImage(files[0]);
    },
    createImage(file) {
      //  var image = new Image();
      ////console.log(image);
      var reader = new FileReader();
      var vm = this;

      reader.onload = e => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function() {
      this.image = "";
    },
    getCompanies() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store.dispatch("company/fetchItemsCompanies").then(response => {
        this.$vs.loading.close("#div-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getDepartments() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store.dispatch("department/getAlls").then(response => {
        this.$vs.loading.close("#div-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getCategories() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store.dispatch("category/fetchItemsCategories").then(response => {
        this.$vs.loading.close("#div-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    addManger() {
      this.user.directmanagers.push({
        level: "",
        manager_id: ""
      });
    },
    removeManger(i) {
      this.user.directmanagers.splice(i, 1);
    },
    getDirectManagers() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store.dispatch("employees/getAlls").then(response => {
        this.$vs.loading.close("#div-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getJobTitles() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store.dispatch("jobTitle/getAlls").then(response => {
        this.$vs.loading.close("#div-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },
    getRoles() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store.dispatch("roles/getAlls").then(response => {
        this.$vs.loading.close("#div-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    },

    getCities() {
      this.$vs.loading({
        container: "#div-loading",
        scale: 0.6
      });
      this.$store.dispatch("city/fetchItemsCities").then(response => {
        this.$vs.loading.close("#div-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      });
    }
  },
  mounted() {
    this.getCompanies();
    this.getDepartments();
    this.getCategories();
    this.getDirectManagers();
    this.getJobTitles();
    this.getRoles();
    this.getCities();
  },
  created() {
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
    if (!moduleDepartmentManagement.isRegistered) {
      this.$store.registerModule("department", moduleDepartmentManagement);
      moduleDepartmentManagement.isRegistered = true;
    }
    if (!moduleCategoriesManagement.isRegistered) {
      this.$store.registerModule("category", moduleCategoriesManagement);
      moduleCategoriesManagement.isRegistered = true;
    }
    if (!moduleEmployeeManagement.isRegistered) {
      this.$store.registerModule("employees", moduleEmployeeManagement);
      moduleEmployeeManagement.isRegistered = true;
    }
    if (!moduleRolesManagement.isRegistered) {
      this.$store.registerModule("roles", moduleRolesManagement);
      moduleRolesManagement.isRegistered = true;
    }
    if (!moduleJobTitleManagement.isRegistered) {
      this.$store.registerModule("jobTitle", moduleJobTitleManagement);
      moduleJobTitleManagement.isRegistered = true;
    }
    if (!moduleCitiesManagement.isRegistered) {
      this.$store.registerModule("city", moduleCitiesManagement);
      moduleCitiesManagement.isRegistered = true;
    }
    if (!moduleAreasManagement.isRegistered) {
      this.$store.registerModule("areas", moduleAreasManagement);
      moduleAreasManagement.isRegistered = true;
    }
  }
};
</script>
